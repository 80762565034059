import { render, staticRenderFns } from "./CompanyNewsArticle3.vue?vue&type=template&id=1742ed8d&scoped=true&"
import script from "./CompanyNewsArticle3.vue?vue&type=script&lang=js&"
export * from "./CompanyNewsArticle3.vue?vue&type=script&lang=js&"
import style0 from "./CompanyNewsArticle3.vue?vue&type=style&index=0&id=1742ed8d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1742ed8d",
  null
  
)

export default component.exports